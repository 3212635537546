import type { LottieProps } from 'react-lottie'

import { joinCommunityAnimationData } from '.'

export const joinCommunityAnimation: LottieProps = {
  isClickToPauseDisabled: true,
  options: {
    loop: true,
    autoplay: true,
    animationData: joinCommunityAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
}
