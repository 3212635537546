import { DiscordIcon } from 'assets/icons'
import { Link } from 'components'
import dynamic from 'next/dynamic'
import React from 'react'

import { joinCommunityAnimation } from './animations'

const Lottie = dynamic(() => import('react-lottie'))

const JoinDiscordSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-evenly px-8 py-32">
      <div className="w-full md:w-1/4 max-w-sm">
        <Lottie {...joinCommunityAnimation} />
      </div>

      <div className="w-full md:w-4/12">
        <div className="max-w-prose">
          <h2 className="text-5xl font-semibold">
            Join Our Community on <span className="text-primary">Discord</span>
          </h2>

          <p className="mt-8 text-lg font-medium">
            We have the most active tech community on discord where students
            help each other out and make great projects together
          </p>

          <div className="flex mt-6 mx-2.5">
            <a
              href="https://devsnest.in/discord"
              target="_blank"
              rel="noreferrer">
              <div className="text-base sm:text-lg py-2 px-6 bg-indigo-400 hover:bg-indigo-500 transition-colors rounded cursor-pointer">
                <div className="flex items-center">
                  <DiscordIcon className="h-5 w-5" />
                  <span className="ml-2">Join Discord</span>
                </div>
              </div>
            </a>
          </div>

          <p className="mt-8 font-medium">
            To get all our notifications{' '}
            <Link
              href="/whatsapp"
              className="text-green-600 hover:text-green-700 transition-colors">
              Join WhatsApp &rarr;
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default JoinDiscordSection
