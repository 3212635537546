import React from 'react'

type propTypes = {
  top: string
  left?: string
  right?: string
}

const RadialGradient: React.FC<propTypes> = (props) => {
  return (
    <div
      className={'absolute -z-0'}
      style={{
        ...props,
        height: '600px',
        width: '600px',
        background:
          'radial-gradient(50% 50% at 50% 50%, #8080FF 0%, rgba(128, 128, 255, 0) 100%)',
        opacity: '0.10'
      }}></div>
  )
}

export default RadialGradient
